import { Box } from "../../components";

const Text = (props) => {
  return (
    <p
      style={{
        color: "#7f8989",
        fontFamily: "Inter",
        fontSize: 20,
        lineHeight: "2rem",
      }}
      {...props}
    />
  );
};

const Default = () => {
  return (
    <Box
      flex
      style={{
        width: "100%",
        zIndex: 2,
        position: "relative",
        backgroundColor: "#000",
        minHeight: 150,
        paddingTop: 20,
      }}
      name="page5"
    >
      <Box
        flex
        row
        gap={100}
        style={{
          maxWidth: "90%",
          margin: "auto",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box flex grow>
          <Text>Услуги оказывает</Text>
          <Text>ИП Стогнушенко Александр Александрович</Text>
        </Box>
        <Box flex grow>
          <Text>ИНН: 613802341081</Text>
          {/* <Text>ОГРН: 1247700481676</Text> */}
        </Box>
        <Box flex grow>
          <Text>
            Воронежская область Бобровский район село Сухая-Березовка ул Карла
            Маркса д6
          </Text>
          {/* <Text>изображения с сайта Freepic</Text> */}
        </Box>
      </Box>
    </Box>
  );
};
export { Default as Page5 };
