const Default = (props) => {
  const { caption, onClick } = props;

  return (
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <input type="checkbox" id="agree" style={{ width: 25, height: 40 }} />
      <label
        htmlFor="agree"
        style={{
          fontFamily: "Inter",
          fontSize: 16,
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={onClick}
      >
        {caption}
      </label>
    </div>
  );
};

export { Default as CheckBox };
